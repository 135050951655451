import {
  Container,
  EContainerVariant,
  ImageWithPlaceholder,
  toImageProps,
  useDragScrolling,
} from '@front/shared/ds';
import { Media } from '@shared/master-types';
import clsx from 'clsx';
import React from 'react';

export type TSpaceMobileAppRow = {
  id?: string;
  blockType?: 'space-mobile-app';
  blockName?: string;
  cards: {
    id?: string;
    title?: string;
    image: Media;
  }[];
};

type TSpaceMobileAppRowProps = {
  row: TSpaceMobileAppRow;
};

export const SpaceMobileAppRow: React.FC<TSpaceMobileAppRowProps> = ({
  row,
}) => {
  const { scrollableRef, bind } = useDragScrolling();

  return (
    <Container variant={EContainerVariant.Full}>
      {row.cards && (
        <div
          ref={scrollableRef}
          className='scrollbar-hidden -mx-4 overflow-scroll'
          {...bind()}
        >
          <div className='flex select-none flex-nowrap gap-2 ps-4 after:-ms-2 after:min-w-4 lg:justify-center lg:gap-6 after:lg:-ms-6'>
            {row.cards.map(item => {
              const imageProps = toImageProps(item.image);
              if (!imageProps) {
                return null;
              }

              return (
                <div
                  className={clsx(
                    'flex min-w-[125px] max-w-[227px] basis-[calc(100%/5_-_8px/5)] flex-col justify-start gap-[13.25px] md:gap-8 lg:basis-[calc(100%/5_-_24px/5)]',
                  )}
                  key={item.id}
                >
                  <ImageWithPlaceholder
                    className='pointer-events-none w-full'
                    {...imageProps}
                  />
                  {item.title && (
                    <h3 className='leading-4 text-center text-[0.6rem] text-interface-800 md:text-base md:leading-mega-loose'>
                      {item.title}
                    </h3>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Container>
  );
};
