import clsx from 'clsx';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  ESelectBackgroundVariant,
  ESelectVariant,
  Select,
  Option,
} from '../Select';
import { ESolidTabSize, ESolidTabTheme, SolidTab } from '../SolidTab';
import { ETabIconVisibility } from './Tabs.constants';

type TTabsProps = {
  tabs: { title?: string; leadingIcon?: React.ReactNode }[];
  activeIndex: number;
  onClick: (index: number) => void;
  wrapperRef: RefObject<HTMLDivElement>;
  selectClassName?: string;
  iconVisibilityConfig?: ETabIconVisibility;
};

export const TabsWithSelect: React.FC<TTabsProps> = ({
  tabs,
  activeIndex,
  onClick,
  wrapperRef,
  selectClassName,
  iconVisibilityConfig = ETabIconVisibility.ShowAlways,
}) => {
  const tabsRef = useRef<HTMLDivElement>(null);
  const [showTabsAsDropdown, setShowTabsAsDropdown] = useState(false);

  const handleResponsiveTabs = useCallback(() => {
    if (!tabsRef.current || !wrapperRef.current) {
      return;
    }

    const tabsRect = tabsRef.current.getBoundingClientRect();
    const wrapperRect = wrapperRef.current.getBoundingClientRect();

    if (tabsRect.width >= wrapperRect.width - 32) {
      if (!showTabsAsDropdown) {
        setShowTabsAsDropdown(true);
      }
    } else if (showTabsAsDropdown) {
      setShowTabsAsDropdown(false);
    }
  }, [showTabsAsDropdown, wrapperRef]);

  useEffect(() => {
    handleResponsiveTabs();

    window.addEventListener('resize', handleResponsiveTabs);
    return () => window.removeEventListener('resize', handleResponsiveTabs);
  }, [handleResponsiveTabs]);

  const options = tabs.map((tab, index) => ({
    value: index.toString(),
    label: tab.title || '',
    leadingIcon: tab?.leadingIcon,
  }));
  return (
    <>
      <div
        className={clsx('relative mx-auto w-full lg:max-w-[38rem]', {
          hidden: !showTabsAsDropdown,
        })}
      >
        <Select
          className={selectClassName}
          variant={ESelectVariant.Widget}
          backgroundVariant={ESelectBackgroundVariant.Widget}
          value={activeIndex}
          labelIcon={tabs[activeIndex]?.leadingIcon}
          options={options}
          onChange={index => {
            if (index !== null) {
              onClick(index);
            }
          }}
        >
          {options.map(option => (
            <Option
              key={option.value}
              label={option.label}
              variant={ESelectVariant.Widget}
            >
              <span className='whitespace-nowrap'>{option.label}</span>
            </Option>
          ))}
        </Select>
      </div>

      <div
        ref={tabsRef}
        className={clsx(
          'absolute left-0 mx-auto flex max-w-[100vw] gap-4 overflow-hidden rounded-4xl',
          showTabsAsDropdown ? '-z-10 opacity-0' : 'visible sticky z-10',
        )}
      >
        {tabs.map((tab, i) => (
          <SolidTab
            iconVisibilityConfig={iconVisibilityConfig}
            key={i}
            leadingIcon={tab.leadingIcon}
            onClick={() => onClick(i)}
            label={tab.title || ''}
            value={tab.title || ''}
            size={ESolidTabSize.Medium}
            theme={ESolidTabTheme.Ordinary}
            isActive={i === activeIndex}
          />
        ))}
      </div>
    </>
  );
};
