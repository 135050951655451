import { ImageWithPlaceholder } from '@front/shared/ds';
import { ImageProps } from 'next/image';
import Link, { LinkProps } from 'next/link';
import React from 'react';
import clsx from 'clsx';

export type TSpaceXlDouble = {
  logo: ImageProps | null;
  icon: ImageProps | null;
  title: string;
  description: React.JSX.Element | null;
  link?: LinkProps;
};

export const SpaceXlDouble: React.FC<TSpaceXlDouble> = ({
  logo,
  icon,
  title,
  description,
  link,
}) => {
  const wrapperProps = {
    className: clsx(
      'flex flex-col gap-6 border-2 border-control-200 p-[22px] shadow-[0px_10px_24px_-8px_rgba(108,111,120,0.20)] transition-shadow lg:p-[30px] xl:min-h-[20.5rem]',
      {
        'cursor-pointer hover:shadow-[0px_16px_32px_-8px_rgba(108,111,120,0.25)]':
          !!link?.href,
      },
    ),
  };

  const content = (
    <>
      {logo || icon ? (
        <div className='flex items-start justify-between gap-1'>
          {logo && (
            <ImageWithPlaceholder className='w-full max-w-44' {...logo} />
          )}
          {icon && (
            <ImageWithPlaceholder className='size-8 object-contain' {...icon} />
          )}
        </div>
      ) : null}
      <div className='mt-auto flex flex-col gap-2 md:gap-4'>
        <h3 className='text-2xl font-semibold leading-extra-loose text-interface-1000 xl:text-3xl xl:leading-loose'>
          {title}
        </h3>
        {description && (
          <div className='text-lg font-normal leading-relaxed text-interface-1000 xl:text-3xl xl:leading-loose'>
            {description}
          </div>
        )}
      </div>
    </>
  );

  if (link?.href) {
    return (
      <Link {...link} {...wrapperProps}>
        {content}
      </Link>
    );
  }

  return <div {...wrapperProps}>{content}</div>;
};
