import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { getLanguageDirection } from '../../../utils';

export const useDirection = (): 'rtl' | 'ltr' | undefined => {
  const { query } = useRouter();
  const [direction, setDirection] = useState<'rtl' | 'ltr'>(); // Default to 'ltr'
  useEffect(() => {
    const locale = query.locale?.toString() || '';
    const newDirection = getLanguageDirection(locale);
    setDirection(newDirection);
  }, [query.locale]);
  return direction;
};
