import { cva, cx } from 'cva';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { ESolidTabSize, ESolidTabTheme } from './SolidTab.constants';
import { ETabIconVisibility } from '../Tabs';

export type TSolidTabProps = {
  value: string;
  label: string;
  size?: ESolidTabSize;
  theme?: ESolidTabTheme;
  onClick: (value: string) => void;
  isActive?: boolean;
  isDisable?: boolean;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  iconVisibilityConfig?: ETabIconVisibility;
};

const tabOptionCVA = cva(
  'group flex snap-center flex-row items-center gap-2.5 whitespace-nowrap rounded-4xl border-2 outline-none transition-all will-change-transform prose-img:size-full',
  {
    variants: {
      size: {
        [ESolidTabSize.Small]:
          'h-8 px-3.5 py-1.5 text-sm font-medium leading-normal',
        [ESolidTabSize.Medium]:
          'h-12 px-[1.4rem] py-3.5 text-base font-medium leading-loose',
        [ESolidTabSize.Large]:
          'h-[3.25rem] px-[1.875rem] py-3.5 text-base font-medium leading-relaxed',
        [ESolidTabSize.Outline]:
          'h-10 px-6 py-2.5 text-base font-medium leading-loose',
      },
      theme: {
        [ESolidTabTheme.Ordinary]: '',
        [ESolidTabTheme.Sticked]: '',
      },
      isActive: {
        true: '',
        false: '',
      },
      isDisable: {
        true: 'opacity-40',
        false: '',
      },
    },
    compoundVariants: [
      {
        isActive: false,
        theme: ESolidTabTheme.Ordinary,
        className:
          'border-transparent text-control-950 hover:text-control-1000 focus:text-control-1000',
      },
      {
        isActive: true,
        theme: ESolidTabTheme.Ordinary,
        className: 'border-control-1000 text-control-1000',
      },
      {
        isActive: false,
        theme: ESolidTabTheme.Sticked,
        className:
          'border-control-1000 bg-control-1000 text-control-50/90 hover:text-control-50 focus:text-control-50',
      },
      {
        isActive: true,
        theme: ESolidTabTheme.Sticked,
        className: 'border-control-1000 bg-control-50 text-control-1000',
      },
      {
        isActive: false,
        theme: ESolidTabTheme.Ordinary,
        size: ESolidTabSize.Outline,
        className:
          'border-2 border-surface-200 text-control-950 hover:text-control-1000',
      },
    ],
    defaultVariants: {
      isActive: false,
      isDisable: false,
    },
  },
);

const iconCVA = cva('flex items-center', {
  variants: {
    size: {
      [ESolidTabSize.Small]: 'size-4',
      [ESolidTabSize.Medium]: 'size-5',
      [ESolidTabSize.Large]: 'size-5',
      [ESolidTabSize.Outline]: 'size-5',
    },
    theme: {
      [ESolidTabTheme.Ordinary]: '',
      [ESolidTabTheme.Sticked]: '',
    },
    isActive: {
      true: 'text-control-950',
      false: '',
    },
    isDisable: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      isActive: false,
      isDisable: false,
      theme: ESolidTabTheme.Ordinary,
      className: 'text-control-950 opacity-30 group-hover:opacity-100',
    },
    {
      isActive: false,
      isDisable: false,
      theme: ESolidTabTheme.Sticked,
      className: 'text-control-950 opacity-60 invert group-hover:opacity-100',
    },
  ],
  defaultVariants: {
    theme: ESolidTabTheme.Ordinary,
    isActive: false,
  },
});

export const SolidTab: React.FC<TSolidTabProps> = props => {
  const {
    value,
    label,
    isActive = false,
    isDisable = false,
    onClick,
    theme = ESolidTabTheme.Ordinary,
    size = ESolidTabSize.Medium,
    leadingIcon,
    trailingIcon,
    iconVisibilityConfig = ETabIconVisibility.ShowAlways,
  } = props;

  const isShowIcon =
    iconVisibilityConfig === ETabIconVisibility.ShowAlways || isActive;

  return (
    <button
      key={value}
      disabled={isDisable}
      className={twMerge(tabOptionCVA({ theme, size, isActive, isDisable }))}
      role='tab'
      type='button'
      aria-selected={isActive}
      tabIndex={isActive ? 0 : -1}
      onClick={() => onClick(value)}
    >
      {!!leadingIcon && isShowIcon && (
        <i className={cx(iconCVA({ theme, isActive, size, isDisable }))}>
          {leadingIcon}
        </i>
      )}
      <span>{label}</span>
      {!!trailingIcon && isShowIcon && (
        <i className={cx(iconCVA({ theme, isActive, size, isDisable }))}>
          {trailingIcon}
        </i>
      )}
    </button>
  );
};
