import React from 'react';

import { formatCurrency } from './formatCurrency';

export type TAccountCardProps = {
  accountTitle: string;
  accountType: string;
  accountId: string;
  totalLabel: string;
  total: number;
  freeFoundsLabel: string;
  freeFounds: number;
  onHoldLabel: string;
  onHold: number;
  accountButton: string;
  id?: string;
};

const AccountCard: React.FC<TAccountCardProps> = props => {
  const {
    accountButton,
    accountId,
    accountTitle,
    accountType,
    freeFounds,
    freeFoundsLabel,
    onHold,
    onHoldLabel,
    total,
    totalLabel,
  } = props;

  return (
    <div className='flex min-w-[233px] flex-col rounded-2xl bg-white p-4 shadow-lg'>
      <div className='self-start py-1 text-[15px] leading-normal text-[#2B334C]'>
        {accountTitle}
      </div>
      <div className='self-start whitespace-nowrap text-xs leading-normal text-[#676F8B]'>
        {accountType} · ID {accountId}
      </div>
      <div className='mt-5 text-ellipsis whitespace-nowrap text-xs leading-normal text-[#676F8B]'>
        {totalLabel}
      </div>
      <div className='text-5xl leading-extra-loose'>
        {formatCurrency(total)}
      </div>
      <div className='mt-4 flex justify-between gap-4 whitespace-nowrap'>
        <div className='flex flex-1 flex-col justify-center'>
          <div className='text-ellipsis text-xs leading-normal text-[#676F8B]'>
            {freeFoundsLabel}
          </div>
          <div className='mt-1 text-ellipsis text-sm leading-normal text-[#2B334C]'>
            {formatCurrency(freeFounds)}
          </div>
        </div>
        <div className='flex flex-1 flex-col justify-center'>
          <div className='text-ellipsis text-xs leading-normal text-[#676F8B]'>
            {onHoldLabel}
          </div>
          <div className='mt-1 text-ellipsis text-sm leading-normal text-[#2B334C]'>
            {formatCurrency(onHold)}
          </div>
        </div>
      </div>
      <div className='mt-6 items-center justify-center whitespace-nowrap rounded-md bg-[#EAECF1] px-16 py-2 text-center text-base leading-normal text-[#2B334C]'>
        {accountButton}
      </div>
    </div>
  );
};

export default AccountCard;
