import {
  Container,
  isRichTextContentEmpty,
  RichText,
  toImageProps,
  toLinkProps,
  TRichTextContent,
} from '@front/shared/ds';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';

import { SpaceXlDouble } from './SpaceXlDouble';

import type { Media, Page } from '@shared/master-types';

export type TSpaceXlDoubleRow = {
  id?: string;
  blockType?: 'space-xl-double';
  blockName?: string;
  countPerRow?: '2' | '3';
  box: {
    logo?: Media;
    icon?: Media;
    title: string;
    description: TRichTextContent;
    links: {
      linkType: 'internal' | 'custom';
      url: string;
      doc: {
        value: string | Page;
        relationTo: 'pages';
      };
      rel: ('nofollow' | 'noopener' | 'noreferrer' | 'external')[];
    }[];
  }[];
};

type TDoubleSpaceRowProps = {
  row: TSpaceXlDoubleRow;
};

export const SpaceXlDoubleRow: React.FC<TDoubleSpaceRowProps> = props => {
  const { countPerRow = '3', box } = props.row;
  const router = useRouter();
  const currentLocale = String(router.query.locale);
  return (
    <Container>
      <div
        className={clsx('grid grid-cols-1 gap-4 md:grid-cols-2', {
          'lg:grid-cols-3': countPerRow === '3',
          'lg:grid-cols-2': countPerRow === '2',
        })}
      >
        {box?.map((element, key) => {
          const link = element.links?.[0];
          return (
            <SpaceXlDouble
              description={
                element.description &&
                !isRichTextContentEmpty(element.description) ? (
                  <RichText content={element.description} />
                ) : null
              }
              title={element.title}
              link={link ? toLinkProps(currentLocale, link) : undefined}
              icon={toImageProps(element?.icon) || null}
              logo={toImageProps(element?.logo)}
              key={key}
            />
          );
        })}
      </div>
    </Container>
  );
};
