import {
  GetStaticProps,
  GetStaticPropsContext,
  GetStaticPropsResult,
} from 'next';
import pino from 'pino';

import { registerMetrics } from './registerMetrics';

const logger = pino({ level: process.env.LOG_LEVEL || 'info' });

export type TGetStaticPropsOptions = {
  jurisdictions?: boolean;
  headerDepth?: number;
  depth?: number;
};

export type TPageProps = {
  url?: string;
  id: string;
};

export const observedGetStaticProps = (
  gSP: (options: TGetStaticPropsOptions) => GetStaticProps<TPageProps>,
  route: string,
  staticPropsParams: TGetStaticPropsOptions,
): GetStaticProps<TPageProps> => {
  return async (context: GetStaticPropsContext) => {
    const start = performance.now();
    const heapStart = process.memoryUsage().heapUsed;
    // Run gSP
    const response = (await gSP(staticPropsParams)(
      context,
    )) as GetStaticPropsResult<TPageProps>;

    const end = performance.now();
    const heapEnd = process.memoryUsage().heapUsed;
    const duration = (end - start) / 1000;
    const usedMemory =
      Math.round(((heapEnd - heapStart) / 1024 / 1024) * 100) / 100; // MB
    logger.debug(
      `GetStaticProps: [${route}] Duration[${duration}s] Memory[${usedMemory}mb]`,
    );

    if ('props' in response) {
      registerMetrics({
        url: response.props.url,
        pageId: response.props.id,
        route,
        duration,
        usedMemory,
      }).catch(err => {
        logger.error(err, 'Failed to register metrics');
      });
    }

    // Return gSP response result
    return response;
  };
};
