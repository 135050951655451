import {
  isRichTextContentEmpty,
  RichText,
  TabsWithSelect,
  toImageProps,
  toLinkProps,
} from '@front/shared/ds';
import { useRouter } from 'next/router';
import React, { useMemo, useRef, useState } from 'react';

import { TCardWithFooterRowProps, TTab } from './CardWithFooterRow.constatns';
import { Content } from './components/Content';

export const CardWithFooterRow: React.FC<TCardWithFooterRowProps> = ({
  tabs,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number): void => {
    setActiveTab(index);
  };
  const router = useRouter();
  const currentLocale = String(router.query.locale || '');
  const preparedTabs: TTab[] | undefined = useMemo(() => {
    return tabs.map(({ title, cards }) => {
      return {
        title,
        cards: cards.map(({ title: cardTitle, description, image, link }) => {
          return {
            title: cardTitle,
            image: toImageProps(image),
            link: link?.[0] ? toLinkProps(currentLocale, link[0]) : null,
            description:
              description && !isRichTextContentEmpty(description) ? (
                <RichText content={description} />
              ) : undefined,
          };
        }),
      };
    });
  }, [tabs, currentLocale]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  if (!preparedTabs?.length) {
    return null;
  }

  return (
    <div className='flex flex-col gap-6'>
      <div
        className='mx-auto flex w-full max-w-[77rem] justify-center px-4'
        ref={wrapperRef}
      >
        <TabsWithSelect
          tabs={preparedTabs}
          activeIndex={activeTab}
          onClick={handleTabClick}
          wrapperRef={wrapperRef}
        />
      </div>
      <Content key={activeTab} cards={preparedTabs[activeTab].cards} />
    </div>
  );
};
