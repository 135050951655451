import {
  EContainerVariant,
  toImageProps,
  Container,
  ImageWithPlaceholder,
} from '@front/shared/ds';
import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React from 'react';

import type { Media } from '@shared/master-types';

export type TCustomImagesRow = {
  customType: 'space-performance' | 'our-offices';
  images: {
    image: string | Media;
    id?: string;
  }[];
  id?: string;
  blockName?: string;
  blockType: 'custom-images';
};

type TCustomSpaceImageRowProps = {
  row: TCustomImagesRow;
};

export const CustomSpaceImageRow: React.FC<TCustomSpaceImageRowProps> = ({
  row,
}) => {
  const { customType } = row;

  if (!row?.images.length) {
    return null;
  }

  const nextImageProps: ImageProps | null = toImageProps(row.images[0].image);

  if (!nextImageProps) {
    return null;
  }

  if (customType === 'our-offices') {
    return (
      <Container variant={EContainerVariant.Full}>
        <div className='scrollbar-hidden -mx-4 flex overflow-x-auto px-4'>
          <ImageWithPlaceholder
            className='m-auto max-w-[680px] tablet:max-w-full'
            {...nextImageProps}
            alt={nextImageProps.alt}
          />
        </div>
      </Container>
    );
  }

  return (
    <Container variant={EContainerVariant.Default}>
      <ImageWithPlaceholder
        className={clsx('m-auto')}
        {...nextImageProps}
        alt={nextImageProps.alt}
      />
    </Container>
  );
};
