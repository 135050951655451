import React from 'react';

export type TDescriptionProps = {
  description: string;
  subDescription: string;
};

const Description: React.FC<TDescriptionProps> = props => {
  const { description, subDescription } = props;

  return (
    <div className='mx-auto flex justify-center gap-5 text-[24px] font-semibold leading-extra-loose max-md:flex-wrap md:py-10 md:text-5xl'>
      <span className='font-sans text-9xl font-light leading-[3rem] text-interface-1000 lg:text-[3.8rem] lg:leading-[.6]'>
        ∞
      </span>
      <div className='block w-full text-center md:text-start lg:max-w-[46.125rem]'>
        <p className='inline text-interface-1000'>{description}</p>&nbsp;
        <p className='inline text-interface-500'>{subDescription}</p>
      </div>
    </div>
  );
};

export default Description;
