import {
  SectionParser,
  useGetLocalesQuery,
  SectionAnimationProvider,
} from '@front/master';
import { NavMenuSectionObserver } from '@front/master/context';
import { ESectionBackground, ESectionVariant } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

import {
  SchemeBreakDownRow,
  TSchemeBreakDownRow,
  CustomFourCardsRow,
  TFourCardsConnectedProps,
  HorizontalBoxRow,
  THorizontalBoxRow,
  CustomDefaultCardsRow,
  TDefaultCardsConnectedProps,
  SpaceWithDynamicTextRow,
  TSpaceWithDynamicTextRow,
  HedgingRow,
  THedgingRow,
  MobileAppRow,
  TMobileAppRow,
  TAccordionWithVideoProps,
  AccordionWithVideo,
  MulticurrencyAccounts,
  TMulticurrencyAccountsProps,
  CustomSpaceImageRow,
  TCustomImagesRow,
  SpaceXlDoubleRow,
  TSpaceXlDoubleRow,
  TCardWithFooterRowProps,
  CardWithFooterRow,
  TSpaceRowsWithTabsRow,
  SpaceRowsWithTabsRow,
  TSpaceMobileAppRow,
  SpaceMobileAppRow,
} from '../../../rows';

export type TSectionsListProps = {
  sections: Section[];
  locale: string;
  draft: boolean;
};

export const SectionsList: React.FC<TSectionsListProps> = props => {
  const { sections, draft, locale } = props;

  const { data: localesObject } = useGetLocalesQuery({ locale, draft });

  const locales = localesObject?.docs;

  const customSectionRowRenderer = (
    row:
      | TSchemeBreakDownRow
      | TFourCardsConnectedProps
      | THorizontalBoxRow
      | TDefaultCardsConnectedProps
      | TSpaceWithDynamicTextRow
      | TMobileAppRow
      | THedgingRow
      | TAccordionWithVideoProps
      | TMulticurrencyAccountsProps
      | TCustomImagesRow
      | TSpaceXlDoubleRow
      | TCardWithFooterRowProps
      | TSpaceRowsWithTabsRow
      | TSpaceMobileAppRow,
    section?: Section,
  ): React.ReactNode => {
    if (
      'logoList' in row &&
      'scheme' in row &&
      row.blockType === 'scheme-breakdown'
    ) {
      return <SchemeBreakDownRow key={row.id} row={row} />;
    }

    if ('customType' in row && row.customType === 'b2broker-main-4-cards') {
      return <CustomFourCardsRow key={row.id} row={row} />;
    }

    if ('box' in row && row.blockType === 'horizontal-box') {
      return <HorizontalBoxRow key={row.id} row={row} />;
    }

    if (
      'customType' in row &&
      row.customType === 'b2broker-main-default-cards'
    ) {
      return <CustomDefaultCardsRow key={row.id} row={row} section={section} />;
    }

    if (row.blockType === 'space-with-dynamic-text') {
      return <SpaceWithDynamicTextRow key={row.id} row={row} />;
    }

    if (row.blockType === 'mobile-app') {
      return <MobileAppRow key={row.id} row={row} />;
    }

    if (row.blockType === 'hedging') {
      return <HedgingRow key={row.id} row={row} />;
    }

    if (row.blockType === 'multicurrency-accounts') {
      return <MulticurrencyAccounts key={row.id} {...row} />;
    }

    if (row.blockType === 'card-with-footer') {
      return <CardWithFooterRow key={row.id} {...row} />;
    }

    if (row.blockType === 'accordion-with-video') {
      return (
        <AccordionWithVideo
          key={row.id}
          {...row}
          isLight={
            section?.backgroundStyle?.background === ESectionBackground.Light
          }
        />
      );
    }

    if (row.blockType === 'custom-images') {
      return <CustomSpaceImageRow key={row.id} row={row} />;
    }

    if (row.blockType === 'space-xl-double') {
      return <SpaceXlDoubleRow key={row.id} row={row} />;
    }

    if (row.blockType === 'space-rows-with-tabs') {
      return (
        <SpaceRowsWithTabsRow
          key={row.id}
          row={row}
          customSectionRowRenderer={customSectionRowRenderer}
        />
      );
    }

    if (row.blockType === 'space-mobile-app') {
      return <SpaceMobileAppRow key={row.id} row={row} />;
    }

    return undefined;
  };

  return (
    <>
      {sections?.map(section => (
        <NavMenuSectionObserver id={section.slug} key={section.id}>
          <SectionAnimationProvider
            isSectionAnimationReady={!section.style?.animationThreshold}
          >
            <SectionParser
              sectionVariant={ESectionVariant.B2Broker}
              key={section.id}
              section={section}
              locales={locales}
              customSectionRowRenderer={customSectionRowRenderer}
            />
          </SectionAnimationProvider>
        </NavMenuSectionObserver>
      ))}
    </>
  );
};
