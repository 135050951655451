import { Page, Section } from '@shared/master-types';
import { GetStaticPaths, GetStaticProps } from 'next';
import React, { useMemo } from 'react';

import {
  isPopulatedReference,
  SectionParser,
  getPage,
  useGetPageQuery,
  getLocales,
  getMeta,
  getHeader,
  getFooter,
  getJurisdictionList,
} from '../../../';
import {
  observedGetStaticProps,
  TPageProps,
} from '../../../prom/observedGetStaticProps';
import wrapper from '../../../store/index';
import { baseApi } from '../../../store/store.constants';

export interface ISectionsPageProps {
  id: string;
  locale: string;
  draft: boolean;
}

const SectionsPage: React.FC<ISectionsPageProps> = props => {
  const { draft, id, locale } = props;
  const { data } = useGetPageQuery({ id, draft, locale });
  const page = data as Page;

  const sections = useMemo(() => {
    const s = page.parts?.sections;

    // might be an empty object
    if (!Array.isArray(s)) {
      return [];
    }
    return [...s].filter(isPopulatedReference) as Section[];
  }, [page]);

  return (
    <main>
      {sections?.map(section => (
        <SectionParser key={section.id} section={section} />
      ))}
    </main>
  );
};

export const getStaticPaths: GetStaticPaths = () => {
  return {
    fallback: 'blocking',
    paths: [],
  };
};

type TGetStaticPropsOptions = {
  jurisdictions?: boolean;
  headerDepth?: number;
  depth?: number;
};

const resolveGetStaticProps = ({
  jurisdictions,
  headerDepth = 1,
  depth = 2,
}: TGetStaticPropsOptions = {}): GetStaticProps<TPageProps> =>
  wrapper.getStaticProps<ISectionsPageProps>(
    ({ dispatch }) =>
      async ({ params, preview: draft = false }) => {
        const { id, locale, jurisdiction } = params as {
          locale: string;
          jurisdiction: string;
          id: string;
        };

        const page = await dispatch(
          getPage.initiate(
            // keep in mind: server params should match client params to catch-up results from redux cache
            { id, draft, locale, ...(depth !== 2 ? { depth } : {}) },
            { forceRefetch: draft },
          ),
        );

        if (!page?.data?.id) {
          return {
            notFound: true,
            revalidate: 10,
          };
        }

        void dispatch(
          getHeader.initiate(
            { locale, ...(headerDepth !== 1 ? { depth: headerDepth } : {}) },
            { forceRefetch: draft },
          ),
        );
        void dispatch(getFooter.initiate({ locale }, { forceRefetch: draft }));

        if (jurisdictions) {
          void dispatch(
            getJurisdictionList.initiate(undefined, { forceRefetch: draft }),
          );
        }

        void dispatch(
          getLocales.initiate({ draft, locale }, { forceRefetch: draft }),
        );
        void dispatch(getMeta.initiate({ locale }, { forceRefetch: draft }));

        await Promise.all(dispatch(baseApi.util.getRunningQueriesThunk()));

        return {
          revalidate: 10,
          props: {
            id,
            draft,
            locale,
            jurisdiction,
            url: page.data?.url,
          },
        };
      },
  );

export const resolveObservedGetStaticProps = (
  params: TGetStaticPropsOptions,
): GetStaticProps =>
  observedGetStaticProps(resolveGetStaticProps, 'SectionsPage', params);

export const getStaticProps: GetStaticProps = resolveObservedGetStaticProps({});

export default SectionsPage;
