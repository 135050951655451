import clsx from 'clsx';
import React from 'react';

import {
  ContentBarItem,
  ERichTextGap,
  TContentBarItem,
} from './ContentBarItem';

export enum ETitleContentGap {
  Sm = 'sm',
  Base = 'base',
}

export type TContentBar = {
  id?: string;
  title: string;
  content: TContentBarItem[];
  titleContentGap?: ETitleContentGap;
  richTextGap?: ERichTextGap;
};

export const ContentBar: React.FC<TContentBar> = ({
  title,
  content,
  titleContentGap,
  richTextGap,
}) => {
  return (
    <div
      className={clsx(
        'flex w-full flex-col gap-4 p-6 prose-ul:mt-0 lg:max-w-[25rem] lg:p-8',
        {
          'lg:gap-8': titleContentGap === ETitleContentGap.Base,
        },
      )}
    >
      {title && (
        <h3 className='text-3xl font-semibold leading-relaxed text-interface-1000 lg:text-5xl lg:leading-extra-loose'>
          {title}
        </h3>
      )}
      {content &&
        content.map((item, index) => (
          <div key={index}>
            <ContentBarItem item={item} richTextGap={richTextGap} />
          </div>
        ))}
    </div>
  );
};
