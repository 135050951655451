import clsx from 'clsx';
import React from 'react';

import Tab from '../Tab/Tab';
import { ETabSize, ETabTheme } from '../Tab/Tab.constants';

export interface ITabsProps {
  value: string;
  options: {
    label: string;
    value: string;
    prefix?: React.JSX.Element | null;
    suffix?: React.JSX.Element | null;
    className?: string;
  }[];
  size: ETabSize;
  theme: ETabTheme;
  onChange: (value: string) => void;
  className?: string;
}

const Tabs: React.FC<ITabsProps> = props => {
  const { options, onChange, theme, size, value, className } = props;

  return (
    <div
      className={clsx(
        'scrollbar-hidden mx-auto flex snap-x snap-mandatory overflow-x-auto px-4',
        theme === ETabTheme.Text ? 'gap-x-8 gap-y-4' : 'gap-2',
        className,
      )}
      role='tablist'
      aria-orientation='horizontal'
    >
      {options.map((option, index) => (
        <Tab
          onClick={onChange}
          key={index}
          theme={theme}
          size={size}
          value={option.value}
          label={option.label}
          isActive={option.value === value}
          prefix={option.prefix}
          suffix={option.suffix}
          className={option.className}
        />
      ))}
    </div>
  );
};

export default Tabs;
